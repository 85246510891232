<template>
  <div>
    <b-card>
      <b-card-text>
        <b-container fluid>
          <b-row align-h="between" align-v="center">
            <b-col cols="12" lg="5">
              <div
                  class="d-flex align-content-center border-bottom-primary w-100"
                  style="padding-bottom: 5px"
              >
                <mdicon :width="20" :height="20" name="magnify"/>

                <input
                    type="text"
                    :placeholder="$t(locale.searchLabel)"
                    class="searchinput w-100"
                    v-model="queryParams.search"
                />
              </div>
            </b-col>
            <b-col cols="12" lg="6">
              <b-row
                  class="justify-content-md-end mt-1 mt-lg-0 justify-content-start"
              >
                <b-col cols="12" lg="6">
                  <router-link
                      :to="{ name: 'golfRelatedMemberCategory' }"
                      class="d-flex align-items-center justify-content-start justify-content-lg-end smallGap"
                  >
                    <mdicon
                        :width="16"
                        :height="16"
                        role="button"
                        name="view-dashboard-outline"
                        class="text-yellow"
                    />
                    <p
                        class="mb-0 font-weight-bold"
                        style="font-size: 12px"
                        v-text="$t(locale.manageMember)"
                    />
                  </router-link>
                </b-col>

                <b-col cols="12" lg="4">
                  <router-link
                      :to="{ name: 'golfRelatedCreateMember' }"
                      class="d-flex align-items-center justify-content-start justify-content-lg-end smallGap"
                  >
                    <mdicon
                        :width="16"
                        :height="16"
                        role="button"
                        name="plus-box-multiple"
                        class="text-yellow"
                    />
                    <p
                        class="mb-0 font-weight-bold"
                        style="font-size: 12px"
                        v-text="$t(locale.addNewUser)"
                    />
                  </router-link>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </b-card-text>
    </b-card>

    <b-card no-body>
      <b-card-text>
        <b-container>
          <b-row align-h="between" align-v="center">
            <b-col cols="12" lg="2" class="pl-2" order="2" order-lg="1">
              <div role="button" class="d-flex align-items-center p-1 smallGap" v-on:click="onOpenFilterDropdown">
                <mdicon :width="20" :height="20" name="filter"/>
                <p class="mb-0 h6" v-text="$t(locale.filterBy)"/>

              </div>
              <div class="absolute w-60 bg-white rounded border shadow-lg md:-ml-20 lg:left-20 lg:top-12"
                   style="z-index:9999"
                   v-if="openFilterDropdown">
                <div class="bg-white border-b">
                  <label class="inline-flex align-items-center item-center w-full pt-1 pb-0.5"
                         :class="{'active-text': filterParameter.type === 'by_member_category'}"
                         v-on:click="setFilter('by_member_category')">
									<span class="ml-2 cursor-pointer">{{
                      $t(locale.byMemberCategory)
                    }}</span>
                  </label>
                  <label class="inline-flex align-items-center item-center w-full pt-0.5 pb-1"
                         :class="{'active-text': filterParameter.type === 'by_member_player'}"
                         v-on:click="setFilter('by_member_player')">
									<span class="ml-2 cursor-pointer">{{
                      $t(locale.byMemberPlayer)
                    }}</span>
                  </label>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="4" class="pl-2" order="2" order-lg="1">
              <v-select
                  v-if="filterParameter.type === 'by_member_category'"
                  v-model="filterParameter.value"
                  :placeholder="$t(locale.filterByMemberCategory)"
                  :options="memberCategory"
                  @option:selected="setCategoryFilter($event)"
                  @search="searchCategories"
                  @option:deselected="removeFilter($event)"
                  label="name"
                  class="resource-selector d-inline-block w-60"
                  value="value"
              />
              <v-select
                  v-if="filterParameter.type === 'by_member_player'"
                  v-model="filterParameter.value"
                  :placeholder="$t(locale.filterByPlayerCategory)"
                  :options="playerCategory"
                  @option:deselected="removeFilter($event)"
                  @option:selected="setPlayerFilter($event)"
                  @search="searchPlayer"
                  label="code"
                  class="resource-selector d-inline-block w-60"
                  value="value"
              />
            </b-col>
            <b-col cols="12" md="5" order="1" order-lg="2">
              <b-row
                  class="justify-content-md-end mt-lg-0 justify-content-start"
              >
                <b-col cols="12" lg="6" order="2" order-lg="1">
                  <a href="javascript:void(0)"
                     @click="exportCustomer"
                     class="d-flex align-items-center cursor-pointer p-1 justify-content-start justify-content-lg-end smallGap"
                  >
                    <mdicon
                        :width="30"
                        :height="30"
                        role="button"
                        name="swap-vertical"
                        class="text-pink"
                    />
                    <p class="mb-0 h5" v-text="$t(locale.exports)"/>
                  </a>
                </b-col>

                <b-col
                    order="1"
                    order-lg="2"
                    cols="12"
                    lg="6"
                    class="bg-secondaryColor d-flex align-items-center rounded"
                >
                  <div class="d-flex align-items-center">
                    <p
                        class="mb-0 p-1 text-white h5"
                        v-text="$t(locale.overview)"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div class="mt-2 w-100">
            <div class="scrol-area table-responsive">
              <div v-if="loading" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>{{ $t('Message.loading') }}...</strong>
              </div>
              <table v-else class="table b-table table-hover">
                <thead class="">
                <tr>
                  <!-- <th class="text-left whitespace-nowrap p-2 md:p-0">
                                          <span class="text-xs text-gray-400">
                                              <b-form-checkbox
                                                  v-model="selected"
                                                  @change="performAllChecked"
                                                  :value="checked"
                                                  class="custom-control-primary my-auto"
                                              />
                                          </span>
                                      </th> -->
                  <th
                      v-for="(item, i) in fields"
                      :key="i"
                      class="text-center whitespace-nowrap p-2 md:p-0"
                  >
                    <!-- <span class="text-xs text-gray-400">{{ renderName(item ) }}</span> -->
                    <div
                        class="flex justify-between gap-5 items-center w-full"
                    >
                        <span class="text-xs text-gray-400">{{
                            $t(item.label)
                          }}</span>
                      <!-- <span class="flex flex-col">
                                                  <svg @click="sortColumn(item)" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                                                  <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                                                  </svg>
                                                  <svg @click="sortColumn(`-${item}`)" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                                                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                                  </svg>
                                              </span> -->
                    </div>
                  </th>
                  <th class="text-center whitespace-nowrap p-2 md:p-0">
                    <span class="text-xs text-gray-400">Action</span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="data in items.results" :key="data.uuid">
                  <!-- <td>
                                          <span>
                                              <b-form-checkbox @change="performSingleChecked" v-model="data.checkbox" />
                                          </span>
                                      </td> -->
                  <td v-for="(item, i) in fields" :key="i">
                      <span
                          class="whitespace-nowrap"
                          v-if="item.key === 'name'"
                      >{{ data.first_name + " " + data.last_name }}</span
                      >
                    <span
                        class="whitespace-nowrap"
                        v-else-if="item.key === 'player_category'"
                    >{{ data[item.key] ? data[item.key].code : "" }}</span
                    >
                    <span
                        class="whitespace-nowrap"
                        v-else-if="item.extra === 'date'"
                    >{{ data[item.key].split("T")[0] }}</span
                    >
                    <span
                        class="whitespace-nowrap"
                        v-else-if="hasInputField.includes(item.key)"
                    >
                        <v-select
                            label="name"
                            @option:selected="setCategory($event, item.key, data)"
                            :filterable="false"
                            :options="memberCategory"
                            @search="searchCategories"
                            :clearable="false"
                            :value="data[item.key] ? data[item.key].name : ''"
                            class="w-100 d-inline-block"
                        />
                      <!-- <b-form-input
                                                  @input="updateFieldValue($event, item, data.uuid)"
                                                  v-model="data[item.key]"
                                              /> -->
                      </span>
                    <router-link
                        :to="{
                          name: 'membersDetail',
                          params: { uuid: data.uuid },
                        }"
                        v-else
                    >
                        <span class="whitespace-nowrap">{{
                            data[item.key]
                          }}</span>
                    </router-link>
                  </td>
                  <td>
                    <div
                        class="d-flex justify-content-between smallGap align-items-center px-2"
                    ></div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="mx-2 mb-2">
              <b-row class="justify-content-center justify-content-lg-between">
                <b-col cols="12" sm="6" class="d-flex align-items-end">
                  <div
                      class="d-flex bigGap justify-content-between align-items-center"
                  >
                    <p class="mb-0 h6" v-text="$t(locale.action)"/>
                    <div class="">
                      <b-form-select
                          v-model="pagination.perPage"
                          id="perPageSelect"
                          size="sm"
                          inline
                          :options="pageOptions"
                      />
                    </div>

                    <b-button
                        size="sm"
                        variant="primary"
                        @click="filterPagination"
                        v-text="$t(locale.apply)"
                    />
                  </div>
                </b-col>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-end flex-sm-column justify-content-between justify-content-sm-end"
                >
                  <b-pagination
                      v-model="pagination.currentPage"
                      :total-rows="pagination.totalMembers"
                      :per-page="pagination.perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>

                  <div
                      class="d-flex justify-content-between justify-content-sm-end smallGap align-items-center mt-1 w-100 order-1 order-sm-2"
                  >
                    <p
                        class="mb-0 d-none d-sm-block h6"
                        v-text="$t(locale.showPerPage)"
                    />
                    <div class="">
                      <b-form-select
                          v-model="pagination.perPage"
                          id="perPageSelect"
                          size="sm"
                          inline
                          :options="pageOptions"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-container>
      </b-card-text>
    </b-card>
        <Modal
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import vSelect from "vue-select";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import accessDenied from "@core/components/permission/accessDenied";
export default {
  name: "golfrelatedmembers",
  components: {
    vSelect,
    accessDenied
  },
  data() {
    return {
      pageOptions: [3, 5, 10, 20],
      pagination: {
        currentPage: 1,
        totalMembers: 0,
        perPage: 20,
      },
      loading: false,
      openFilterDropdown: false,
      items: {results: []},
      queryParams: {
        limit: 100,
        search: "",
        filter: "",
      },
      hasInputField: ["member_category"],
      filters: "",
      filterBy: [
        {name: "Memeber Category", value: "member_category"},
        {name: "Member Player", value: "member_player"},
      ],
      fields: [
        {
          key: "name",
          label: "golfRelatedCard.table.name",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "golf_id",
          label: "golfRelatedCard.table.id",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "email",
          label: "golfRelatedCard.table.email",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "created_at",
          label: "golfRelatedCard.table.memberSince",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
          extra: "date",
        },
        {
          key: "member_category",
          label: "golfRelatedCard.table.memberCategory",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "250px",
          },
        },
        {
          key: "player_category",
          label: "golfRelatedCard.table.playerCategory",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
        {
          key: "HCP",
          label: "golfRelatedCard.table.hcp",
          //tdClass: 'bTableThStyle',
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
      ],
      locale: {
        searchLabel: "golfRelatedCard.searchLabel",
        manageMember: "golfRelatedCard.manageMember",
        addNewUser: "golfRelatedCard.addNewUser",
        overview: "golfRelatedCard.overview",
        action: "golfRelatedCard.action",
        exports: "Sidebar.golfRelated.exports",
        apply: "golfRelatedCard.apply",
        showPerPage: "golfRelatedCard.showPerPage",
        byMemberCategory: "golfRelatedCard.MemberCategory",
        byMemberPlayer: "golfRelatedCard.MemberPlayer",
        filterByMemberCategory: "golfRelatedCard.filterByMemberCategory",
        filterByPlayerCategory: "golfRelatedCard.filterByPlayerCategory",
        filterBy: "filterBy",
      },
      memberCategory: [],
      playerCategory: [],
      filterParameter: {
        type: '',
        value: ''
      }
    };
  },
  watch: {
    "queryParams.search": {
      handler(value) {
        this.queryParams.search = value;
        this.getMembers();
      },
    },
    "queryParams.filter": {
      handler(value) {
        this.queryParams.filter = value.value;
        this.getMembers();
      },
    },
    "filterParameter.value": {
      handler(value) {
        if (value === '' || value === null) {
          this.removeFilter();
        }

      },
    },
  },
  methods: {
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {
        if (err.response.status === 403) {

        }
      })
    },
    getMembers() {
      this.loading = true;
      this.$useJwt
          .getMembers({URL: "", method: "get"}, {params: this.queryParams})
          .then((res) => {
            this.loading = false;
            this.items = res.data.data;
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.status === 403){
               this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
              this.$bvModal.show("modal-access-denied");
            }
          });
    },
    filterPagination() {
      delete this.queryParams.search;
      this.queryParams.limit = this.pagination.perPage;
      this.getMembers();
    },
    searchCategories(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteMemberCate(loading, search, this);
      }
    },
    searchRemoteMemberCate: debounce(function (loading, search, vm) {
      this.$useJwt
          .getMembersCategory({URL: "?search=" + escape(search), method: "get"})
          .then((res) => {
            this.memberCategory = res.data.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    setCategory(event, field, fullData) {
      const payload = {};
      payload[field] = event;

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: this.$t("Message.wait"),
          variant: "warning",
          // text: this.$t('Message.subscriptionProcess'),
          waiting: true,
        },
      });

      this.$useJwt
          .getMembers({URL: `/${fullData.uuid}`, method: "put"}, payload)
          .then((res) => {
            this.getMembers();
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            if (err.response.data.message) {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data.message,
                  "AlertTriangleIcon",
                  "danger"
              );
            } else {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            }
          });
    },
    exportCustomer() {
      this.$router.push({
        name: 'golfRelatedExports',
        query: {target: 'customer'}
      })
    },
    onOpenFilterDropdown() {
      this.openFilterDropdown = !this.openFilterDropdown
    },
    searchPlayer(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteMemberPlayer(loading, search, this);
      }
    },
    searchRemoteMemberPlayer: debounce(function (loading, search, vm) {
      this.$useJwt
          .getMembersPlayer({URL: "?search=" + escape(search), method: "get"})
          .then((res) => {
            this.playerCategory = res.data.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    setFilter(mode) {
      this.filterParameter.type = mode
      this.openFilterDropdown = false
    },
    setCategoryFilter(item) {
      this.filterParameter.value = item
      this.queryParams['member_category__name'] = item.name
      this.getMembers()
    },
    setPlayerFilter(item) {
      this.filterParameter.value = item
      this.queryParams['player_category__code'] = item.code
      this.getMembers()
    },
    removeFilter(item) {
      delete this.queryParams['member_category__name']
      delete this.queryParams['player_category__code']
      this.getMembers()
    },
  },

  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getMembers();
  },
};
</script>

<style lang="scss" scoped>
.searchinput {
  border-bottom: 1px solid black;
  border: none;
  outline-width: 0;
}

.searchinput:focus {
  outline: none;
}

.table th {
  padding: 0.4rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table tbody,
td {
  padding: 0.4rem !important;
  // padding-bottom: .4rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.active-text {
  color: #4fcfc0 !important;
}
</style>
